* {
    margin: 0%;
    padding: 0%;
    text-decoration: none;
  }
  
  .table-container {
    overflow-y: auto;
  }
  .userServey{
    max-width: 260px!important;
  }
 .side{
  width: 100px;
 }
  .ms-auto.d-lg-none.close {
    display: none;
  }
  .sidebarButton.ms-auto {
    background-color: transparent;
    color: gray;
    border: #f8f9fa;
  }
  .second.ms-auto.d-lg-none {
    display: block;
    background-color: transparent;
    color: gray;
    border: #f8f9fa;
  }
  
  .table-scroll {
    overflow-y: auto;
    max-height: 200px; /* Adjust the max height as needed */
  }
  
  .loginPage {
    background: hsla(0, 0%, 100%, 0.2);
    width: 100%;
    height: 100vh;
    backdrop-filter: 'blur(30px)'
  }
  
  .loginBox {
    height: auto;
    width: 40%;
    border-radius: 10px;
    background: hsla(0, 0%, 100%, 0.8);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
.loginBox img{
    width: 215px;
    height: 80px;
    margin-top: 30px;
}
.loginDiv{
    padding: 0px 40px;
}
.loginDiv p{
    font-size: 18px;
    font-weight: 400;
    word-spacing: 3px;
    letter-spacing: 2px;
}
.loginDiv input{
    width: 100%;
    outline: none!important;
    border: 1px solid #c0beb2;
    border-radius: 5px;
    padding: 7px 20px;
    background: #f8f9fa!important;
    margin: 13px 0px;
}

.loginDiv button{
    width: 100%;
    outline: none!important;
    border: 1px solid ;
    border-radius: 5px;
    padding: 7px 20px;
    background: #007BFF!important;
  margin-bottom: 60px;
  margin-top: 20px;
  color: white;
  font-size: 16px!important;
}
@media screen and (max-width: 767px) {
  .hide-on-small-and-tablet {
    display: none; /* hide the div on small screens */
  }
}

/* Media query for tablet screens (768px to 1023px) */
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hide-on-small-and-tablet {
    display: none; /* hide the div on tablet screens */
  }
}

@media screen and (min-width: 400px) and (max-width: 800px) {
  .loginPage {
    background: hsla(0, 0%, 100%, 0.2);
    width: 100%;
    height: 100vh;
  }
  .loginBox img{
    width: 150px;
    height: 80px;
    margin-top: 30px;
}
.loginBox {
  height: auto;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.loginDiv p{
  font-size: 14px;
}
.loginDiv input{
  padding: 5px 10px;
}
.loginDiv{
  padding: 0px 20px;
}
}

@media screen and (min-width: 200px) and (max-width: 399px) {
  .loginBox img{
    width: 130px;
    height: 80px;
    margin-top: 30px;
}
.loginBox {
  height: auto;
  width: 90%;
  padding: 20px 0px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden!important;
}
.loginDiv p{
  font-size: 10px;
}
.loginDiv input{
  padding: 5px 15px;
}
.loginPage {
  background: hsla(0, 0%, 100%, 0.2);
  width: 100%;
  height: 100vh;
}
.loginDiv{
  padding: 0px 20px;
  overflow: hidden!important;
}


}

