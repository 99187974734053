.sidebarArea {
  flex-shrink: 0;
  width: $sidebarWidth;
  height:100vh;
  background-color: $sidebarColor;
  .sidenav-bg:hover {
    background-color: $gray-100;
    border-radius: $border-radius;
  }
}

@include media-breakpoint-down(lg) {
  .sidebarArea {
    position: fixed;
    overflow: auto;
    z-index: 1;
    margin-left: -$sidebarWidth;
    transition: 0.2s ease-in;
    &.showSidebar {
      margin-left: 0px;
    }
  }
}
