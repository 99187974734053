
.input_dropdown{
  border-radius: 3px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.cardSubClass {
  background: white;
  padding: 1.2rem 1.2rem;
  height: 6.5rem;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.cardSubClass div p{
    color: rgb(104, 102, 102);
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 200px;
    
}
.cardSubClass .iconClass{
    font-size: 24px;
    opacity: 0.9;
}

.topCards {
  display: grid;
  grid-gap: 1rem;
  margin: 0px 10px 0px 0px;
}

.icon1Div{
  background: #eef5f9;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.custom-tooltip{
  background: white;
  padding: 10px;
  color: #8884d8;
  border-radius: 4px;
}

.sevenDaysChart,.allStats p{
  color: rgb(104, 102, 102);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-size: 14px;
  font-weight: 200px;
}
.allStats{
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* Screen larger than 600px? 2 column */
@media (min-width: 600px) {
  .topCards { grid-template-columns: repeat(2, 1fr); }
}

/* Screen larger than 900px? 3 columns */
@media (min-width: 900px) {
  .topCards { grid-template-columns: repeat(4, 1fr); }
}



