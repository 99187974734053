* {
    margin: 0px;
    list-style: none;
}
.sidebarNav {
    transition: all 0.3s ease-in-out;
  }
  .no-hover-color:hover {
    background-color: transparent !important;
    color: inherit !important;
}

  .sidenav-bg {
    margin-bottom: 3px;
  }
  
  .dropdown-menu.show {
    margin-top: 3px;
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  

.container-fluid {
    padding: 0px !important;
}

.text-primary {
    color: #007BFF !important;
    background: #f5fafd;
    border-radius: 4px;
}

.pagination {
    --bs-pagination-color: #007BFF !important;
}

.page-link.active,
.active>.page-link {
    background-color: #007BFF !important;
    border-color: #007BFF !important;
}
.table{
    min-width: 100%!important;
    width: max-content!important;
}
.table > :not(caption) > * > * {
    padding: 0.9rem 0.6rem!important;
}
.contentArea {
    flex-grow: 1;
    width: 80%;
}
.pageWrapper{
    overflow-x: hidden!important;
}
.sidebarArea {
    z-index: 10 !important;
    min-height: 100vh;
    height: auto;
}

.search-div {
    margin-top: 14px;
    margin-bottom: 4px;
    padding: 0px 10px;
}

.search-input {
    border: 1px solid #dfddd3;
    border-radius: 5px;
    /* width: 22%; */
    height: 28px;
    padding: 4px 0px 4px 5px;
    margin-left: 2px;
}

.search-input .search-icon {
    font-size: 15px;
    margin: 0px 10px 0px 5px;
    color: #a09f9b;
}

.search-input input {
    border: none;
    outline: none;
    background: transparent;
    width: 100%;
    border-right: 1px solid #dfddd3;
    padding: 4px 0px 4px 5px;
    height: 22px;
    font-size: 14px;
}

.search-input input::placeholder {
    font-size: 14px;
}

.uploadCSVbutton {
    margin: 13px 0px;
}

.uploadCSVbutton .aploadDIv {
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    text-align: center;
    height: 33px;
    width: fit-content;
    background: #007BFF;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    flex-direction: row;
}


.aploadIcon {
    color: white;
    font-size: 18px;
}

.react-datepicker{
    z-index: 1000!important;
    height: auto!important;
    width: auto!important;
}



@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);

body {
    font-family: "Lato", sans-serif;
}

.form {
    width: 400px;
    margin: 20px 0px;
}

.file-upload-wrapper {
    position: relative;
    width: 100%;
    height: 60px;
}

.file-upload-wrapper:after {
    content: attr(data-text);
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    padding: 10px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: 60px;
    line-height: 40px;
    color: #999;
    border-radius: 5px 10px 10px 5px;
    font-weight: 300;
}

.file-upload-wrapper:before {
    content: "Choose CSV";
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 60px;
    background: #007bff;
    color: #fff;
    font-weight: 700;
    z-index: 25;
    font-size: 16px;
    line-height: 60px;
    padding: 0 15px;
    text-transform: uppercase;
    pointer-events: none;
    border-radius: 0 5px 5px 0;
}

.file-upload-wrapper:hover:before {
    background: #007bff;
}

.file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 60px !important;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
}

.sprucecss {
    align-items: flex-start;
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.05);
    color: #444;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    inset: auto auto 1rem 1rem;
    line-height: 1.5;
    max-width: 11rem;
    padding: 1.5rem;
    position: fixed;
    text-decoration: none;
}

.sprucecss img {
    height: 1.5rem;
    width: auto;
}

#dataTable td button {
    padding: 3px 8px;
    font-size: 11px;
    outline: none;
    border: none;
    border-radius: 4px;
    font-weight: 400;
    color: white;
}

.btn-active {
    background: green;
}

.btn-expired {
    background: red;
}

.btn-trial {
    background: #007BFF;
}

/* .drClass button{
   border: 1px solid lightgray!important;
   background: #fff;  
   padding: 4px 10px;
   border-radius: 5px;
} */

.custom-dropdown {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-toggle {
    padding: 0.19rem 1rem;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    color: #888888;
  }
  
  .datePicker {
    width: 100%;
    border: none;
  }
  .dropdown-menu .apply{
    outline: none;
    border: none;
    padding: 3px 10px;
    font-size: 14px;
    background: #007BFF;
    color: white;
    border-radius: 4px;
  }
  .dropdown-menu .clear{
    outline: none;
    border: none;
    padding: 3px 10px;
    font-size: 14px;
    background: #dfddd3;
    border-radius: 4px;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #333;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .dropdown-menu.show {
    display: block;
    padding: 10px;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.45rem 1.5rem;
    clear: both;
    font-weight: normal;
    color: #333;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    transition: background-color 0.1s ease;
  }
  
  .dropdown-item:hover {
    background-color: #f8f9fa;
  }
  
  .upload-div {
    display: flex;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .upload-icon {
    margin-right: 5px;
  }
  
  .upload-text {
    font-size: 14px;
  }
  
  .view-button {
    padding: 0;
    margin-left: 10px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  .view-button svg {
    font-size: 20px;
  }
  
  @media screen and (max-width: 837px) {
    .uploadCSVbutton{
        flex-wrap: wrap;
    }
    .uploadCSVbutton .eyeButton{
        margin-top: 5px;
    }
    .aploadDIv{
        margin-inline-start: 0px !important;
        margin-top: 5px;
    }
 
  }
  @media screen and (min-width: 1500px) {
    .contentArea{
        width: 100%!important;
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1300px) {
    .contentArea{
        width: 75%!important;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 991px) {
    .contentArea{
        width: 100%!important;
    }
  }

@media screen and (min-width: 600px) and (max-width: 800px) {
    .search-input {
        width: 28%;
        height: 28px;
    }
    .contentArea{
        width: 100%!important;
    }
}

@media screen and (min-width: 400px) and (max-width: 599px) {
    .search-input {
        width: 33%;
        height: 28px;
    }
    .date-inputs{
        flex-wrap: wrap;
    }
    .contentArea{
        width: 100%!important;
    }
}

@media screen and (max-width: 399px) {
    .search-input {
        width: 50%;
        height: 28px;
    }
    .date-inputs{
        flex-wrap: wrap;
    }
    .contentArea{
        width: 100%!important;
    }
}