
@media screen and (max-width: 767px) {
    .hide-on-small-and-tablet {
      display: none; /* hide the div on small screens */
    }
    .smallphone{
      padding: 5px!important;
    }
  }
  
  /* Media query for tablet screens (768px to 1023px) */
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .hide-on-small-and-tablet {
      display: none; /* hide the div on tablet screens */
    }
    .smallphone{
      padding: 5px!important;
    }
  }

  .hide-on-small-and-tablet:hover{
    background: #b6b5b2!important;
  }
  .btHover:hover{
    background: #b6b5b2!important;
  }